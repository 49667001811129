<template>
  <app-list-view
    server-side
    app="expense"
    model="expensecategory"
    api-url="expense/expense-category/"
    :title="$t('menu.expenseCategory')"
    :createTo="{ name: 'expenseCategoryCreate' }"
    :editTo="{ name: 'expenseCategoryEdit' }"
    :headers="headers"
  />
</template>

<script>
import AppListView from '@components/AppListView'

export default {
  name: 'expenseCategoryList',
  components: {
    AppListView
  },
  computed: {
    headers() {
      return [
        { text: this.$t('fields.expenseCategoryName'), value: 'name' },
        { text: this.$t('fields.state'), value: 'state' },
        {
          text: this.$t('fields.expenseType'),
          value: 'businessCategory',
          link: {
            prepareRouter: this.expenseTypeLink
          },
          hideFilter: true
        },
        {
          text: this.$t('fields.active'),
          value: 'active',
          check: true,
          groupable: false,
          hideFilter: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          groupable: false,
          hideFilter: true
        }
      ]
    }
  },
  methods: {
    expenseTypeLink(item) {
      return {
        name: 'expenseType',
        query: { expense_categories: item.id }
      }
    }
  }
}
</script>
